<template>
    <div class="navmb">
        <div class="flex flexa">
            <el-icon :size="20" color="#FF6A00" class="icon bgshadow">
                <Back class="astyle" @click="$router.back()" />
            </el-icon>
            <span class="">公司文档</span>
        </div>
    </div>
    <p class="h20"></p>
    <div class="m-resultl">
        <UserCompanyList @query="companyQuery">
            <template #="{ list, loading }">
                <el-select-v2 v-model="search_form.companyId" placeholder="选择公司" class="w464 marr30" size="large"
                    filterable clearable :props="{ label: 'name', value: 'id' }" :loading="loading" :options="list"
                    @change="search_form.categoryId = ''; $nextTick($refs.CompanyDocument.companyDocument); queryList()" />
            </template>
        </UserCompanyList>
        <CompanyDocument ref="CompanyDocument" :params="{
                companyId: search_form.companyId,
                pageNumber: 1,
                pageSize: 5000
            }">
            <template #="{ list, loading }">
                <!-- <el-select-v2
                    v-model="search_form.categoryId"
                    placeholder="选择文档类型"
                    class="w242"
                    size="large"
                    filterable
                    clearable
                    :props="{ label: 'categoryTitle', value: 'id' }"
                    :loading="loading"
                    :options="list"
                    @change="queryList()" /> -->
                <el-cascader @change="changeList" v-model="search_form.categoryId" size="large" class="w300" :props="companyCategory.props"
                    :options="lists" clearable placeholder="请选择公司文档分类" />
            </template>
        </CompanyDocument>
        <div class="result">
            <div class="item" v-loading="loading">
                <ul class="ul-listl3">
                    <li v-for="(item, index) in list" :key="index">
                        <router-link :to="`/knowledgeInfo/${item.id}`" class="con">
                            <!-- <div class="tit">新增Vastbase<span>数据</span>库请求结构</div> -->
                            <div class="tit">{{ item.docTitle }}</div>
                            <div class="desc">
                                {{ item.docContent }}
                            </div>
                            <div class="stips">所属分类：{{ item.companyCategorName }}</div>
                        </router-link>
                    </li>
                </ul>
                <div class="m-pages">
                    <div class="pages">
                        <el-pagination v-model:currentPage="search.pageNumber" @update:page-size="getDocList()"
                            @update:current-page="getDocList" :page-sizes="[5, 10, 15, 20]"
                            layout=" prev, pager, next, sizes, jumper" :total="total">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
//图标
import { Back } from '@element-plus/icons-vue'
import UserCompanyList from '@/components/data/UserCompanyList'
import CompanyDocument from '@/components/data/CompanyDocument'
export default {
    components: {
        Back,
        UserCompanyList,
        CompanyDocument
    },
    data() {
        return {
            companyCategory: {
                props: {
                    checkStrictly: false,
                    emitPath: true,
                    lazy: false,label: 'categoryTitle', value: 'id',children: 'list'
                },
                options: [],
            },
            loading: false,
            search_form: {
                companyId: this.$route.query.id,
                categoryId: '',
                docTitle: '',
            },
            search: {
                companyId: '',
                categoryId: '',
                docTitle: '',
                pageNumber: 1,
                pageSize: 10,
                isSearchCompany: 1
            },
            list: [],lists:[],
            total: 0,
        };
    },
    mounted() {
        // console.log('11111',this.$route.params.id)
        this.queryList()
    },
    methods: {
        getList(){
            let that = this
            that.$api.document.companyDocument({ pageNumber: 1, pageSize: 1000, companyId: this.search.companyId }).then(res=>{
                console.log('分类',res)
                if(res){
                    this.lists = res.records
                }
            })
        },
        companyQuery(e) {
            // 不管有没有第一项公司，做查询
            // 改变选项，更新公司文档分类
            if (e[0]) {
                this.search.companyId = this.search_form.companyId = e[0].id;
            }
            this.getDocList(1)
        },
        queryList() {
            this.search.companyId = this.search_form.companyId;
            this.search.categoryId = this.search_form.categoryId[this.search_form.categoryId.length-1];
            this.search.docTitle = this.search_form.docTitle;
            this.getDocList(1)
            this.getList()
        },
        changeList(){this.queryList()},
        async getDocList(pageNumber) {
            try {
                pageNumber && (this.search.pageNumber = pageNumber)
                this.loading = true
                console.log('参数', this.search)
                const { records, total } = await this.$api.know.getDocList(this.search)
                this.list = records
                this.total = total
            } catch (e) {
                return console.log(e)
            } finally {
                this.loading = false
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.navmb {
    .icon {
        margin-right: 10px;
    }
}

.m-searchl {
    overflow: hidden;

    .search {
        overflow: hidden;
        margin-bottom: 20px;
    }

    .history {
        overflow: hidden;

        .ll {
            float: left;
            font-size: 12px;
            color: #999999;
            line-height: 30px;
            margin-right: 15px;
        }

        .rr {
            overflow: hidden;

            span {
                display: block;
                float: left;
                min-width: 82px;
                height: 30px;
                line-height: 30px;
                text-align: center;
                font-size: 12px;
                color: #666666;
                margin-right: 20px;
                padding: 0 15px;
            }
        }
    }
}

.m-resultl {
    overflow: hidden;
    background-color: #fff;
    padding: 20px 30px;

    .tips {
        font-size: 12px;
        color: #4C4C4C;
        line-height: 1.416em;
        overflow: hidden;
        margin-bottom: 20px;
    }

    .result {
        overflow: hidden;

        .item {
            overflow: hidden;
            margin-bottom: 19px;
        }
    }
}

.ul-listl1 {
    font-size: 0;
    margin-bottom: 30px;

    li {
        display: inline-block;
        margin-right: 10px;

        .con {
            display: block;
            width: 120px;
            height: 32px;
            line-height: 32px;
            background: #F7F7F7;
            text-align: center;
            display: block;
            font-size: 12px;
            color: #181819;
            transition: .5s;
            cursor: pointer;
        }

        .con:hover {
            color: #fff;
            background: #FF6A00;
            box-shadow: 0px 2px 8px 0px #FFC49A;
        }
    }

    li.on {
        .con {
            color: #fff;
            background: #FF6A00;
            box-shadow: 0px 2px 8px 0px #FFC49A;
        }
    }
}

.m-titl1 {
    overflow: hidden;
    padding-bottom: 11px;
    border-bottom: 1px solid #E8E9EB;
    margin-bottom: 26px;

    .more {
        float: right;
        font-size: 12px;
        color: #ff6a00;
        line-height: 1.416em;
        transition: .5s;
    }

    .more:hover {
        color: #f83;
    }

    .tit {
        font-size: 12px;
        color: #4C4C4C;
        line-height: 1.416em;
        overflow: hidden;
    }
}

.m-titl1.styl2 {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 10px;
}

.ul-listl2 {
    overflow: hidden;

    li {
        margin-bottom: 21px;
        overflow: hidden;

        .con {
            display: block;
            overflow: hidden;

            .rr {
                float: right;
                max-width: 350px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                font-size: 12px;
                line-height: 1.5em;
                color: #9c9c9c;
                margin-left: 20px;
            }

            .tit {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                font-size: 12px;
                line-height: 1.5em;
                color: #151822;

                span {
                    color: #ff6a00;
                }

                transition: .5s;
            }
        }

        .con:hover {
            .tit {
                color: #ff6a00;
            }
        }
    }
}

.ul-listl2.styl2 {
    margin-bottom: 57px;

    li {
        margin-bottom: 0;

        .con {
            padding: 30px 0 24px;
            border-bottom: 1px dashed #E6E6E6;
        }
    }
}

.ul-listl3 {
    overflow: hidden;
    margin-bottom: 40px;

    .con {
        display: block;
        overflow: hidden;
        padding: 20px 0 17px;
        border-bottom: 1px dashed #E6E6E6;

        .tit {
            font-size: 14px;
            line-height: 1.583em;
            color: #151822;
            margin-bottom: 10px;
            transition: .5s;

            span {
                color: #ff6a00;
            }
        }

        .desc {
            font-size: 12px;
            color: #686868;
            line-height: 1.666em;
            margin-bottom: 10px;
            text-overflow: ellipsis;
            display: -webkit-box;
            overflow: hidden;
            overflow-wrap: break-word;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
        }

        .stips {
            font-size: 12px;
            line-height: 2.333em;
            overflow: hidden;
            color: #9c9c9c;
        }
    }

    .con:hover {
        .tit {
            color: #ff6a00;
        }
    }
}

.m-pages {
    overflow: hidden;
    text-align: center;
    font-size: 0;
    margin-bottom: 10px;

    .pages {
        display: inline-block;
        vertical-align: middle;
    }
}
</style>