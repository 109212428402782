<template>
    <slot :list="list" :loading="loading">

    </slot>
</template>
<script>
export default {
    emits: ['query'],
    props: {
        params: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            list: [],
            loading: false,
        }
    },
    methods: {
        async companyDocument() {
            try {
                if (this.params.companyId) {
                    this.loading = true
                    const result = await this.$api.document.companyDocument(this.params)
                    this.list = result.records
                    this.$emit('query', result.records)
                } else {
                    this.list = []
                    this.total = 0
                    this.loading = false
                }
            } catch (e) {
                return console.log(e)
            } finally {
                this.loading = false
            }
        }
    },
    mounted() {
        this.companyDocument()
    }
}
</script>