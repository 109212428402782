<template>
    <slot :list="list" :loading="loading">

    </slot>
</template>
<script>
export default {
    emits: ['query'],
    data() {
        return {
            list: [],
            loading: false,
        }
    },
    methods: {
        async userCompanyLists() {
            try {
                this.loading = true
                this.list = await this.$api.document.userCompanyLists()
                console.log('获取下拉列表',this.list)
                this.$emit('query', this.list)
            } catch (e) {
                return console.log(e)
            } finally {
                this.loading = false
            }
        }
    },
    mounted() {
        this.userCompanyLists()
    }
}
</script>